import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { GeneralFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { OnlineMarketingState, Pagination } from '../../@types/reduxStates';
import { OnlineMarketingReponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: OnlineMarketingState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    groupBy: 0,
    campaignType: 0,
    campaignStatus: 0,
    clicksFrom: 0,
    clicksTill: 0,
    costsFrom: 0,
    costsTill: 0,
    conversionsFrom: 0,
    conversionsTill: 0,
    impressionsFrom: 0,
    impressionsTill: 0,
    columns: []
  },
  response: {
    rows: [],
    total: {
      name: '',
      campaignId: '',
      adGroupId: '',
      campaignType: '',
      costs: 0,
      clicks: 0,
      ctr: 0,
      impressions: 0,
      conversions: 0,
      conversionsValue: 0,
      status: ''
    },
    totalCount: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'onlineMarketing',
  initialState,
  reducers: {
    setFilters: (state: any, action: PayloadAction<GeneralFilterBarState>) => {
      state.filters = action.payload;
    },
    getOnlineMarketing: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOnlineMarketingSuccess: (
      state: any,
      action: PayloadAction<OnlineMarketingReponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getOnlineMarketingError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchOnlineMarketingFilters(filters: GeneralFilterBarState) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
  };
}

export function fetchOnlineMarketing(
  filterBar: GeneralFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(getOnlineMarketing());
    try {
      const params = {
        skip,
        limit,
        by,
        order,
        from: filterBar.timePeriod?.startFilter,
        till: filterBar.timePeriod?.endFilter,
        groupBy: filterBar.groupBy ? filterBar.groupBy : undefined,
        campaignType: filterBar.campaignType
          ? filterBar.campaignType
          : undefined,
        campaignStatus: filterBar.campaignStatus
          ? filterBar.campaignStatus
          : undefined,
        costsFrom: filterBar.costsFrom ?? undefined,
        costsTill: filterBar.costsTill ?? undefined,
        clicksFrom: filterBar.clicksFrom ?? undefined,
        clicksTill: filterBar.clicksTill ?? undefined,
        impressionsFrom: filterBar.impressionsFrom ?? undefined,
        impressionsTill: filterBar.impressionsTill ?? undefined,
        conversionsFrom: filterBar.conversionsFrom ?? undefined,
        conversionsTill: filterBar.conversionsTill ?? undefined
      };
      const response = await axios.get('/api/v1/onlineMarketing', {
        params,
        signal: abortController.signal
      });
      dispatch(getOnlineMarketingSuccess(response.data));
    } catch (error) {
      dispatch(getOnlineMarketingError(error as APIError));
    }
  };
}

export const {
  setFilters,
  getOnlineMarketing,
  getOnlineMarketingError,
  getOnlineMarketingSuccess
} = slice.actions;

export default slice.reducer;
