import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { store } from '../store';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { FiltersState } from '../../@types/reduxStates';
import { FiltersResponse } from '../../@types/responsesAPI';

// ----------------------------------------------------------------------

const initialState: FiltersState = {
  response: {
    articles: {
      maxMargin: 0,
      maxReturns: 0,
      maxRevenue: 0,
      minMargin: 0,
      minReturns: 0,
      minRevenue: 0
    },
    orders: {
      maxMargin: 0,
      maxRevenue: 0,
      minMargin: 0,
      minRevenue: 0
    },
    orderStatus: [],
    countries: [],
    shops: [],
    sources: [],
    customers: [],
    categories: [],
    suppliers: [],
    warehouses: [],
    stockManagementTimePeriods: [],
    manufacturers: [],
    customConfigs: []
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters(state: FiltersState, action: PayloadAction<FiltersResponse>) {
      state.response = action.payload;
    },
    getFilters: (state: FiltersState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getFiltersSuccess: (
      state: FiltersState,
      action: PayloadAction<FiltersResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = {
        ...state.response,
        ...action.payload
      };
    },
    getFiltersError: (state: FiltersState, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchFilterValues() {
  return async (dispatch: any) => {
    try {
      dispatch(getFilters());
      const response = await axios.get(`/api/v2/filters`);
      dispatch(getFiltersSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const { setFilters, getFilters, getFiltersSuccess, getFiltersError } =
  slice.actions;
