import { useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../css/filter.css';
import { DateRangePicker } from 'react-date-range';
import {
  getMonth,
  addDays,
  addYears,
  differenceInCalendarDays,
  differenceInMonths,
  endOfDay,
  endOfYear,
  format,
  intlFormat,
  isLastDayOfMonth,
  isSameDay,
  isToday,
  isYesterday,
  lastDayOfMonth,
  startOfDay,
  startOfMonth,
  startOfYear,
  sub,
  subDays,
  subYears
} from 'date-fns';
import { Popover, TextField, useTheme, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isFirstDayOfMonth, isThisMonth } from 'date-fns/esm';
import isThisYear from 'date-fns/esm/isThisYear/index.js';
import useLocales from '../../hooks/useLocales';
import { SelectTimePeriodClasses } from '../../theme/forms/FormsClasses';

const { de, en } = require('date-fns/locale');

export interface ISelectTimePeriodProps {
  disabled?: boolean;
  startFilter: string;
  endFilter: string;
  fillMaxWidth?: boolean;
  onChangePeriod: (timePeriod: {
    startFilter: string;
    endFilter: string;
  }) => void;
}

export default function SelectTimePeriod(props: ISelectTimePeriodProps) {
  const {
    startFilter,
    endFilter,
    onChangePeriod,
    disabled = false,
    fillMaxWidth = true
  } = props;
  const classes = SelectTimePeriodClasses({ fillMaxWidth });
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['filter']);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme: Theme = useTheme();

  const getFormat = () => {
    if (currentLang.value === 'de-DE') {
      return 'dd.MM.yyyy';
    }
    return 'dd/MM/yyyy';
  };

  const dateDisplay = (date: string) => {
    const nDate = new Date(date);
    return intlFormat(
      nDate,
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      {
        locale: currentLang.value
      }
    );
  };

  const startDate = dateDisplay(startFilter); // TODO: this is not live update on select
  const endDate = dateDisplay(endFilter); // TODO: this is not live update on select

  const date = [
    {
      startDate: new Date(startFilter),
      endDate: new Date(endFilter),
      key: 'selection'
    }
  ];

  const handleOpen = (event: any) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const changeDateFocus = (ranges: any) => {
    const timePeriod = {
      startFilter: format(ranges.selection.startDate, 'yyyy-MM-dd'),
      endFilter: format(ranges.selection.endDate, 'yyyy-MM-dd')
    };
    onChangePeriod(timePeriod);
    const startDateString = ranges.selection.startDate.toString();
    const endDateString = ranges.selection.endDate.toString();
    if (
      startDateString !== endDateString ||
      startDateString.indexOf('00:00:00') === -1
    ) {
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderStaticRangeLabel = (props: any) => <div>{props.label}</div>;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <TextField
        disabled={disabled}
        aria-describedby={id}
        className={classes.root}
        type="text"
        label={translate('time_period')}
        size="small"
        aria-readonly={true}
        value={
          startDate === endDate ? `${startDate}` : `${startDate} - ${endDate}`
        }
        onClick={handleOpen}
        InputProps={{
          readOnly: true,
          className: classes.input
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <DateRangePicker
          // color={() => theme.palette.primary.main}
          rangeColors={[
            theme.palette.primary.main,
            theme.palette.secondary.main
          ]}
          disabled={disabled}
          className={classes.calendar}
          locale={currentLang.value === 'de-DE' ? de : en}
          onChange={changeDateFocus}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={date}
          direction="horizontal"
          minDate={subYears(new Date(), 5)}
          maxDate={new Date()}
          dateDisplayFormat={getFormat()}
          showMonthAndYearPickers={true}
          renderStaticRangeLabel={renderStaticRangeLabel}
          inputRanges={[
            {
              label: translate('filter:days_up_to_today'),
              range(value: any) {
                return {
                  startDate: addDays(
                    startOfDay(new Date()),
                    (Math.max(Number(value), 1) - 1) * -1
                  ),
                  endDate: endOfDay(new Date())
                };
              },
              getCurrentValue(range: any) {
                const { endDate } = range;
                if (!isSameDay(endDate || 0, endOfDay(new Date()))) return '-';
                if (!range.startDate) return '∞';
                const diff =
                  differenceInCalendarDays(
                    endOfDay(new Date()),
                    range.startDate
                  ) + 1;
                return diff.toString();
              },
              isSelected() {
                return false;
              }
            }
          ]}
          staticRanges={[
            {
              label: translate('filter:today'),
              hasCustomRendering: true,
              range: () => ({
                startDate: new Date(),
                endDate: new Date()
              }),
              isSelected(range: any) {
                return (
                  isToday(range.startDate || 0) && isToday(range.endDate || 0)
                );
              }
            },
            {
              label: translate('filter:yesterday'),
              hasCustomRendering: true,
              range: () => ({
                startDate: subDays(new Date(), 1),
                endDate: subDays(new Date(), 1)
              }),
              isSelected(range: any) {
                return (
                  isYesterday(range.startDate || 0) &&
                  isYesterday(range.endDate || 0)
                );
              }
            },
            {
              label: translate('filter:last_7_days'),
              hasCustomRendering: true,
              range: () => ({
                startDate: subDays(new Date(), 7),
                endDate: subDays(new Date(), 1)
              }),
              isSelected(range: any) {
                return (
                  isSameDay(range.endDate, subDays(new Date(), 1)) &&
                  isSameDay(range.startDate, subDays(new Date(), 7))
                );
              }
            },
            {
              label: translate('filter:last_14_days'),
              hasCustomRendering: true,
              range: () => ({
                startDate: subDays(new Date(), 14),
                endDate: subDays(new Date(), 1)
              }),
              isSelected(range: any) {
                return (
                  isSameDay(range.endDate, subDays(new Date(), 1)) &&
                  isSameDay(range.startDate, subDays(new Date(), 14))
                );
              }
            },
            {
              label: translate('filter:last_30_days'),
              hasCustomRendering: true,
              range: () => ({
                startDate: subDays(new Date(), 30),
                endDate: subDays(new Date(), 1)
              }),
              isSelected(range: any) {
                return (
                  isSameDay(range.endDate, subDays(new Date(), 1)) &&
                  isSameDay(range.startDate, subDays(new Date(), 30))
                );
              }
            },
            {
              label: translate('filter:month_to_date'),
              hasCustomRendering: true,
              range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: new Date()
              }),
              isSelected(range: any) {
                const firstDate = range.startDate || 0;
                const firstDayMonth =
                  isFirstDayOfMonth(firstDate) &&
                  isThisMonth(firstDate) &&
                  isThisYear(firstDate);
                return firstDayMonth && isToday(range.endDate || 0);
              }
            },
            {
              label: translate('filter:last_month'),
              hasCustomRendering: true,
              range: () => ({
                startDate: startOfMonth(sub(new Date(), { months: 1 })),
                endDate: lastDayOfMonth(sub(new Date(), { months: 1 }))
              }),
              isSelected(range: any) {
                const firstDay = isFirstDayOfMonth(range.startDate || 0);
                const lastDay = isLastDayOfMonth(range.endDate || 0);
                const months = differenceInMonths(
                  new Date(),
                  range.startDate || 0
                );
                return firstDay && lastDay && months === 1;
              }
            },
            {
              label: translate('filter:this_year'),
              hasCustomRendering: true,
              range: () => ({
                startDate: startOfYear(new Date()),
                endDate: new Date()
              }),
              isSelected(range: any) {
                const firstDate = range.startDate || 0;
                const secondDate = range.endDate || 0;
                return (
                  isFirstDayOfMonth(firstDate) &&
                  getMonth(firstDate) === 0 &&
                  isThisYear(firstDate) &&
                  isToday(secondDate)
                );
              }
            },
            {
              label: translate('filter:last_year'),
              hasCustomRendering: true,
              range: () => ({
                startDate: sub(startOfYear(new Date()), { years: 1 }),
                endDate: endOfYear(sub(new Date(), { years: 1 }))
              }),
              isSelected(range: any) {
                const firstDate = range.startDate || 0;
                const secondDate = range.endDate || 0;

                const isLastYear =
                  isThisYear(addYears(firstDate, 1)) &&
                  isThisYear(addYears(secondDate, 1));
                const isFirstDay = isFirstDayOfMonth(firstDate);
                const istFirstMonth = getMonth(firstDate) === 0;
                const isLastDayOfYear = isLastDayOfMonth(secondDate);
                const isLastMonth = getMonth(secondDate) === 11;

                return (
                  isLastYear &&
                  isFirstDay &&
                  istFirstMonth &&
                  isLastDayOfYear &&
                  isLastMonth
                );
              }
            }
          ]}
        />
      </Popover>
    </>
  );
}
