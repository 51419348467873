import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaChartFiltersState, OrderFiltersState } from '../@types/filterBars';
import {
  CustomerSelectItem,
  SortBy,
  SourceSelectItem
} from '../@types/filters';
import { Pagination } from '../@types/reduxStates';
import { RootState } from '../redux/rootReducer';
import {
  fetchOrderList,
  fetchOrdersTab,
  fetchRevenueByChannel,
  fetchRevenueByCountry,
  resetOrdersTab,
  sortRevenueByCountryBy
} from '../redux/slices/ordersTab';
import useFilters from './useFilters';
import { TableHeaderCell } from '../@types/tableRows';
import useLocales from './useLocales';
import { divideIfNotZero } from '../utils/helper';

function useOrdersTab() {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation(['component']);
  const { currentLang } = useLocales();
  const { data, APIStatus, error } = useSelector(
    (state: RootState) => state.ordersTab
  );

  const orderListabortController = new AbortController();
  const revenueByCountryAbortController = new AbortController();

  const {
    response: { sources, customers }
  } = useFilters();

  const summary: Array<TableHeaderCell> = useMemo(
    () => [
      {
        id: 1,
        field: 'orderId',
        label: translate('order_id'),
        align: 'left',
        minWidth: 220,
        canSort: true
      },
      {
        id: 2,
        field: 'channel',
        label: translate('channel'),
        align: 'center',
        minWidth: 160,
        canSort: true
      },
      {
        id: 3,
        field: 'orderDate',
        label: translate('order_date'),
        align: 'center',
        minWidth: 160,
        canSort: true
      },
      {
        id: 4,
        field: 'orderStatusId',
        label: translate('status'),
        align: 'center',
        minWidth: 160,
        canSort: true
      },
      {
        id: 5,
        field: 'orderPaymentMethod',
        label: translate('payment'),
        align: 'center',
        minWidth: 160,
        canSort: true
      },
      {
        id: 6,
        field: 'orderCountryName',
        label: translate('ship_country'),
        align: 'center',
        minWidth: 160,
        canSort: true
      },
      {
        id: 7,
        field: 'orderItems',
        label: translate('article_qty'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value2: data.orderList.response.total.itemsTotal,
          value: data.orderList.response.rows.reduce(
            (acc, order) => acc + order.orderItems,
            0
          ),
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 8,
        field: 'orderAmountNet',
        label: translate('order_volume_net'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: data.orderList.response.rows.reduce(
            (acc, order) => acc + parseFloat(order.orderAmountNet),
            0
          ),
          value2: data.orderList.response.total.amountTotalNet,
          type: 'currency',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 9,
        field: 'orderAmount',
        label: translate('order_volume_gross'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: data.orderList.response.rows.reduce(
            (acc, order) => acc + parseFloat(order.orderAmount),
            0
          ),
          value2: data.orderList.response.total.amountTotal,
          type: 'currency',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 10,
        field: 'orderMargin',
        label: translate('margin'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value:
            divideIfNotZero(
              data.orderList.response.rows.reduce(
                (acc, order) => acc + order.orderMargin,
                0
              ),
              data.orderList.response.rows.length
            ) / 100,
          value2: data.orderList.response.total.marginTotal / 100,
          type: 'percent',
          align: 'right',
          paddingRight: '8px',
          calculation: 'average'
        }
      }
    ],
    [data, currentLang]
  );

  function getOrdersUrlFilterValues(
    orderFiltersState: OrderFiltersState
  ): OrderFiltersState {
    const f: OrderFiltersState = {
      ...orderFiltersState,
      timePeriod: { ...orderFiltersState.timePeriod },
      amount: {
        ...orderFiltersState.amount,
        range: { ...orderFiltersState.amount.range },
        config: { ...orderFiltersState.amount.config }
      },
      margin: {
        ...orderFiltersState.margin,
        range: { ...orderFiltersState.margin.range },
        config: { ...orderFiltersState.margin.config }
      }
    };
    if (hash.length > 0) {
      hash
        .split('#')[1]
        .split('&')
        .forEach((part) => {
          const p = part.split('=');
          const name = p[0];
          const value = p[1];
          switch (name) {
            case 'from':
              {
                f.timePeriod = { ...f.timePeriod, startFilter: value };
              }
              break;
            case 'till':
              {
                f.timePeriod = { ...f.timePeriod, endFilter: value };
              }
              break;
            case 'status':
              {
                f.status = parseInt(value, 10);
              }
              break;
            case 'amountFrom':
              {
                f.amount.range.min = Number(value);
              }
              break;
            case 'amountTo':
              {
                f.amount.range.max = Number(value);
              }
              break;
            case 'margeFrom':
              {
                f.margin.range.min = Number(value);
              }
              break;
            case 'margeTo':
              {
                f.margin.range.max = Number(value);
              }
              break;
            case 'countryId':
              {
                f.countryId = parseInt(value, 10);
              }
              break;
            case 'returns':
              {
                f.returns = parseInt(value, 10) > 0;
              }
              break;
            case 'channelTags':
              {
                f.channelTags = value
                  .split('-')
                  .map((id) =>
                    sources.find((s) => s.sourceId === parseInt(id, 10))
                  )
                  .filter(
                    (sourceSelectItem): sourceSelectItem is SourceSelectItem =>
                      !!sourceSelectItem
                  );
              }
              break;
            case 'customers':
              {
                f.customers = value
                  .split('-')
                  .map((id) =>
                    customers.find((c) => c.customerId === parseInt(id, 10))
                  )
                  .filter((cu): cu is CustomerSelectItem => !!cu);
              }
              break;
          }
        });
    }
    return f;
  }

  return {
    data,
    APIStatus,
    error,
    summary,
    getOrdersUrlFilterValues,
    resetTab: () => {
      dispatch(resetOrdersTab());
    },
    getOrdersTabData: (articleId: number, lang: string) => {
      dispatch(fetchOrdersTab(articleId, lang));
    },
    cancelRevenueByCountry: () => {
      revenueByCountryAbortController.abort();
    },
    updateRevenueByCountry: (
      filters: AreaChartFiltersState,

      idArticle?: number
    ) => {
      dispatch(
        fetchRevenueByCountry(
          filters,
          revenueByCountryAbortController,
          idArticle
        )
      );
    },
    sortRevenueByCountry: (orderBy: string, order: 'asc' | 'desc') => {
      dispatch(
        sortRevenueByCountryBy(data.revenueByCountry.data, orderBy, order)
      );
    },

    updateRevenueByChannel: (
      filters: AreaChartFiltersState,
      idArticle?: number
    ) => {
      dispatch(fetchRevenueByChannel(filters, idArticle));
    },
    cancelOrderListFetch: () => {
      orderListabortController.abort();
    },
    updateOrders: (
      filters: OrderFiltersState,
      pagination: Pagination,
      sortBy: SortBy,
      idArticle?: string
    ) => {
      dispatch(
        fetchOrderList(
          filters,
          pagination,
          sortBy,
          orderListabortController,
          idArticle
        )
      );
    }
  };
}

export default useOrdersTab;
