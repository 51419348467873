import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ProductListFilterBarState } from '../@types/filterBars';
import { SortBy } from '../@types/filters';
import { Pagination } from '../@types/reduxStates';
import { RootState } from '../redux/rootReducer';
import { fetchProductItems } from '../redux/slices/productItems';
import { TableHeaderCell } from '../@types/tableRows';
import useLocales from './useLocales';
import { divideIfNotZero, unknownToNumber } from '../utils/helper';

function useProductItems() {
  const { hash } = useLocation();
  const { currentLang } = useLocales();
  const dispatch = useDispatch();
  const {
    response: {
      rows,
      count,
      stock,
      avPrice,
      orders,
      revenue,
      margin,
      returns,
      activityNormal,
      activityCritical
    },
    filters,
    APIStatus,
    error
  } = useSelector((state: RootState) => state.productItems);
  const { t: translate } = useTranslation(['component']);

  function getProductsUrlFilterValues(
    filterBarState: ProductListFilterBarState
  ): ProductListFilterBarState {
    const f: ProductListFilterBarState = {
      ...filterBarState,
      timePeriod: { ...filterBarState.timePeriod }
    };
    if (hash.length > 0) {
      hash
        .split('#')[1]
        .split('&')
        .forEach((part) => {
          const p = part.split('=');
          const name = p[0];
          const value = p[1];
          switch (name) {
            case 'from': {
              f.timePeriod = { ...f.timePeriod, startFilter: value };
              break;
            }
            case 'till': {
              f.timePeriod = { ...f.timePeriod, endFilter: value };
              break;
            }
            case 'source': {
              f.source = Number(value);
              break;
            }
            case 'customerId': {
              f.customerId = Number(value);
              break;
            }
            case 'shop': {
              f.shop = Number(value);
              break;
            }
            case 'searchName': {
              f.searchName = value;
              break;
            }
            case 'searchId': {
              f.searchId = value;
              break;
            }
            case 'revenueFrom': {
              f.revenue.range.min = Number(value);
              break;
            }
            case 'revenueTo': {
              f.revenue.range.max = Number(value);
              break;
            }
            case 'margeFrom': {
              f.margin.range.min = Number(value);
              break;
            }
            case 'margeTo': {
              f.margin.range.max = Number(value);
              break;
            }
            case 'returnsFrom': {
              f.returns.range.min = Number(value);
              break;
            }
            case 'returnsTo': {
              f.returns.range.max = Number(value);
              break;
            }
            case 'isActivity': {
              f.isActivity = Number(value) > 0;
              break;
            }
            case 'isBundle': {
              f.isBundle = Number(value) > 0;
              break;
            }
            case 'isRecommendations': {
              f.isRecommendations = Number(value) > 0;
              break;
            }
            case 'isCritical': {
              f.isCritical = Number(value) > 0;
              break;
            }
            case 'stock': {
              f.stock = Number(value);
              break;
            }
            default: {
              break;
            }
          }
        });
    }
    return f;
  }

  const activeMarketIdsSize = useMemo(() => {
    const am = new Set<number>();
    rows.forEach((r) => {
      r.activeMarketsData.forEach((aMarket) => am.add(aMarket.marketId));
    });
    return am.size;
  }, [rows]);

  const summary: Array<TableHeaderCell> = useMemo(
    () => [
      {
        id: 1,
        field: 'stock',
        label: translate('stock'),
        align: 'center',
        minWidth: 366,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.stock)
            .reduce((prev, next) => prev + next, 0),
          value2: stock,
          align: 'left',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'integer'
        }
      },
      {
        id: 2,
        field: 'av_price',
        label: translate('av_price'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: divideIfNotZero(
            rows.map((r) => r.avPrice).reduce((prev, next) => prev + next, 0),
            rows.length
          ),
          value2: avPrice,
          align: 'center',
          paddingRight: '8px',
          calculation: 'average',
          type: 'currency'
        }
      },
      {
        id: 3,
        field: 'orders',
        label: translate('orders'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.orders)
            .reduce((prev, next) => prev + next, 0),
          value2: orders,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'integer'
        }
      },
      {
        id: 4,
        field: 'net_revenue',
        label: translate('net_revenue'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.revenue)
            .reduce((prev, next) => prev + next, 0),
          value2: revenue,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'currency'
        }
      },
      {
        id: 5,
        field: 'margin',
        label: translate('margin'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.margin)
            .reduce((prev, next) => prev + next, 0),
          value2: margin,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'currency'
        }
      },
      {
        id: 6,
        field: 'return',
        label: translate('return'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.returns)
            .reduce((prev, next) => prev + next, 0),
          value2: returns,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'integer'
        }
      },
      {
        id: 7,
        field: 'activity',
        label: translate('activity'),
        align: 'center',
        minWidth: 160,
        canSort: false,
        summary: {
          value: `(${activityNormal}/${activityCritical})`,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 8,
        field: 'recommendations',
        label: translate('recommendations'),
        align: 'center',
        minWidth: 160,
        canSort: false,
        summary: {
          value: `(0/0)`,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 9,
        field: 'active_markets',
        label: translate('active_markets'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: activeMarketIdsSize,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'integer'
        }
      },
      {
        id: 10,
        field: 'salesRank',
        label: translate('sales_rank'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: 0,
          type: 'no'
        }
      },
      {
        id: 11,
        field: 'productHealth',
        label: translate('product_health'),
        align: 'right',
        minWidth: 160,
        canSort: true,
        summary: {
          value: 0,
          type: 'no'
        }
      }
    ],
    [currentLang, activeMarketIdsSize]
  );

  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getProducts = (
    filters: ProductListFilterBarState,
    pagination: Pagination,
    sortBy: SortBy,
    variant?: string
  ) => {
    cancelFetch();
    dispatch(
      fetchProductItems(
        filters,
        pagination,
        sortBy,
        abortController.current,
        variant
      )
    );
  };

  return {
    rows,
    count,
    stock,
    avPrice,
    orders,
    revenue,
    margin,
    returns,
    activityNormal,
    activityCritical,
    filters,
    APIStatus,
    error,
    summary,
    getProductsUrlFilterValues,
    cancelFetch,
    getProducts
  };
}

export default useProductItems;
