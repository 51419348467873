import { alpha, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import BoltIcon from '@material-ui/icons/Bolt';
// import EuroIcon from '@material-ui/icons/Euro';
// import AccountTreeIcon from '@material-ui/icons/AccountTree';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import ArchitectureIcon from '@material-ui/icons/Architecture';
import useArticlesDetail from '../../../hooks/useArticlesDetail';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    minWidth: 290,
    height: 210,
    backgroundColor: alpha('#6c7b88', 0.1),
    backdropFilter: 'blur(8px)',
    // border: '1px solid #fff',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0, 3),
    // marginLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },

  linesConainer: {
    border: '1px solid red'
  },
  title: {
    // color: '#6c7b88',
    // color: theme.palette.text.primary,
    color: '#3c454e',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(2)
  },
  line: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1)
  },
  iconContainer: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.5),
    border: `1px solid ${alpha('#fff', 0.5)}`,
    borderRadius: '50%'
  },
  icon: {
    color: '#fff',
    fontSize: 18
    // color: alpha('#fff', 0.5),
  },
  text: {
    color: '#3c454e'
    // color: '#6c7b88'
    // color: theme.palette.text.primary
  },
  underline: {
    color: '#3c454e',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  label: {
    // border: '1px solid red',
    width: '90px',
    color: '#3c454e',
    // color: '#6c7b88',
    // color: theme.palette.text.primary,
    marginRight: theme.spacing(1)
  }
}));

type Props = {
  onVariantsClicked: () => void;
};

export function ProductInfo({ onVariantsClicked }: Props) {
  // const { name, ean, asin, sku, variants, onVariantsClicked } = props;
  const {
    article: { name, ean, asin, sku, variants }
  } = useArticlesDetail();
  const classes = useStyles();
  const { t: translate } = useTranslation(['component']);
  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {name}
      </Typography>

      <Box className={classes.line}>
        <Typography variant="h6" fontWeight={500} className={classes.label}>
          EAN
        </Typography>
        <Typography variant="h6" fontWeight={700} className={classes.text}>
          {ean}
        </Typography>
      </Box>

      <Box className={classes.line}>
        <Typography variant="h6" fontWeight={500} className={classes.label}>
          ASIN
        </Typography>
        <Typography variant="h6" fontWeight={700} className={classes.text}>
          {asin}
        </Typography>
      </Box>
      <Box className={classes.line}>
        <Typography variant="h6" fontWeight={500} className={classes.label}>
          SKU
        </Typography>
        <Typography variant="h6" fontWeight={700} className={classes.text}>
          {sku}
        </Typography>
      </Box>

      <Box className={classes.line}>
        <Typography variant="h6" fontWeight={500} className={classes.label}>
          {translate('variants')}
        </Typography>
        {/* <Button variant="outlined"> */}
        <Box onClick={() => onVariantsClicked()}>
          <Typography
            variant="h6"
            fontWeight={700}
            className={classes.underline}
          >
            {variants}
          </Typography>
        </Box>
        {/* </Button> */}
      </Box>
    </Box>
  );
}
