import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  TablePagination,
  useMediaQuery,
  CardHeader,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import OrdersTable from './OrdersTable';
import { OrdersFilterBar } from './OrdersFilterBar';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import useOrdersTab from '../../hooks/useOrdersTab';
import { SortBy } from '../../@types/filters';
import { useCopyFilters } from '../../hooks/useCopyFilters';

type OrdersProps = {
  title?: string;
  articleId?: string;
  tab?: string;
};

export default function Orders({ title, articleId, tab = '' }: OrdersProps) {
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t: translate } = useTranslation(['component']);
  const classes = TablePaginationClasses();
  const {
    data: {
      orderList: {
        response: {
          totalCount: { count }
        },
        filters
      }
    }
  } = useOrdersTab();

  const { setUrlValues, getUrlTableValues } = useCopyFilters();
  const rowsPerPageOpts = [10, 25, 50, 100];
  const initialTableValues = useMemo(
    () =>
      getUrlTableValues({
        page: 0,
        rowsPerPage: rowsPerPageOpts[0],
        by: 'orderDate',
        order: 'desc'
      }),
    []
  );
  const [sortBy, setSortBy] = useState<SortBy>({
    by: initialTableValues.by,
    order: initialTableValues.order
  });

  const [rowsPerPage, setRowsPerPage] = useState(
    initialTableValues.rowsPerPage
  );
  const [page, setPage] = useState(initialTableValues.page);

  useEffect(() => {
    setUrlValues(filters, {
      page,
      rowsPerPage,
      by: sortBy.by,
      order: sortBy.order
    });
  }, [filters]);

  useEffect(() => {
    setUrlValues(
      filters,
      {
        page,
        rowsPerPage,
        by: sortBy.by,
        order: sortBy.order
      },
      false
    );
  }, [sortBy, page, rowsPerPage]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <OrdersFilterBar
          setInitialPage={() => setPage(0)}
          page={page}
          rowsPerPage={rowsPerPage}
          sortBy={sortBy}
          articleId={articleId}
          tab={tab}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={translate('order_listing')} />
          <CardContent>
            <OrdersTable
              rowsPerPage={rowsPerPage}
              sortBy={sortBy}
              onSortChange={setSortBy}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <TablePagination
                component="div"
                className={classes.root}
                rowsPerPageOptions={rowsPerPageOpts}
                labelRowsPerPage={
                  !isMobile ? translate('global:rows_per_page') : ''
                }
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                showFirstButton={true}
                showLastButton={true}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
