import { Box, makeStyles, Typography, Theme } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { DateRangeFilter } from '../../@types/filters';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    '& a': {
      color: theme.palette.text.primary,
      textDecoration: 'none'
    }
  }
}));

export type CSVExportData = {
  headers: Array<{ label: string; key: string }>;
  data: Array<{ [key: string]: string | number | undefined }>;
  date: DateRangeFilter;
};

type Props = {
  label: string;
  fileName: string;
  data: CSVExportData;
};

function ExportCSV({ label, fileName, data }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CSVLink
        data={data.data}
        headers={data.headers}
        filename={fileName}
        separator=";"
        /* onClick={() => {
                    if(ready){
                        done(true)
                    }else{
                        done(false)
                    }
                    //return false; // 👍🏻 You are stopping the handling of component
                }} */
      >
        <Typography component="span" variant="body2" fontWeight={500}>
          {label}
        </Typography>
      </CSVLink>
    </Box>
  );
}

export default ExportCSV;
