import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';

import { sortByProperty } from '../../utils/sortValues';

import { APIError, APIStatus } from '../../@types/APIStatus';
import { ProductListFilterBarState } from '../../@types/filterBars';
import { Pagination, ProductListState } from '../../@types/reduxStates';
import { ProductListRow } from '../../@types/tableRows';
import { ProductListResponse } from '../../@types/responsesAPI';
import { SortBy } from '../../@types/filters';

const initialState: ProductListState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    source: 0,
    shop: 0,
    searchName: '',
    searchId: '',
    stock: 0,
    customerId: 0,
    isBundle: false,
    // country: '0',
    revenue: {
      config: { min: 0, max: 0 },
      range: { min: 0, max: 0 }
    },
    margin: {
      config: { min: 0, max: 0 },
      range: { min: 0, max: 0 }
    },
    returns: {
      config: { min: 0, max: 0 },
      range: { min: 0, max: 0 }
    },
    isActivity: false,
    isRecommendations: false,
    isCritical: false
  },
  response: {
    rows: [],
    count: 0,
    revenue: 0,
    orders: 0,
    margin: 0,
    stock: 0,
    avPrice: 0,
    returns: 0,
    activityCritical: 0,
    activityNormal: 0,
    maxReturns: 0,
    productHealth: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'itemsProducts',
  initialState,
  reducers: {
    setItemsProducts: (
      state: any,
      action: PayloadAction<Array<ProductListRow>>
    ) => {
      state.items = action.payload;
    },
    setFilters: (
      state: any,
      action: PayloadAction<ProductListFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getItemsProducts: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getItemsProductsSuccess: (
      state: any,
      action: PayloadAction<ProductListResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getOneProductSuccess: (
      state: any,
      action: PayloadAction<ProductListRow>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      // state.items.push(action.payload);
    },
    getItemsProductsError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchProductItems(
  filters: ProductListFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController,
  variant: string = ''
) {
  const {
    timePeriod,
    shop,
    source,
    customerId,
    searchName,
    searchId,
    stock,
    isBundle,
    isActivity,
    isCritical,
    isRecommendations,
    revenue: {
      range: { min: rMin, max: rMax }
    },
    margin: {
      range: { min: mMin, max: mMax }
    },
    returns: {
      range: { min: rtMin, max: rtMax }
    }
  } = filters;
  return async (dispatch: any) => {
    dispatch(getItemsProducts());
    dispatch(setFilters(filters));
    try {
      const response: AxiosResponse = await axios.get('/api/v2/productList', {
        signal: abortController.signal,
        params: {
          variant: variant.length > 0 ? variant : undefined,
          skip,
          limit,
          by,
          order,
          from: timePeriod.startFilter,
          till: timePeriod.endFilter,
          isBundle: isBundle ? 1 : undefined,
          isActivity: isActivity ? 1 : undefined,
          isCritical: isCritical ? 1 : undefined,
          isRecommendations: isRecommendations ? 1 : undefined,
          source: source > 0 ? source : undefined,
          shop: shop > 0 ? shop : undefined,
          customerId: customerId > 0 ? customerId : undefined,
          searchName: searchName.length > 0 ? searchName : undefined,
          searchId: searchId.length > 0 ? searchId : undefined,
          stock: stock > 0 ? stock : undefined,
          revenueFrom: rMin,
          revenueTo: rMax,
          marginFrom: mMin,
          marginTo: mMax,
          returnsFrom: rtMin,
          returnsTo: rtMax
        }
      });
      dispatch(getItemsProductsSuccess(response.data));
    } catch (error) {
      dispatch(getItemsProductsError(error as APIError));
    }
  };
}

export function sortBy(
  list: Array<ProductListRow>,
  orderBy: string,
  order: 'asc' | 'desc'
) {
  const orderedList = sortByProperty(list, orderBy, order);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setItemsProducts(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setItemsProducts,
  setFilters,
  getItemsProducts,
  getItemsProductsSuccess,
  getItemsProductsError,
  getOneProductSuccess
} = slice.actions;
