import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { fetchFilterValues } from '../redux/slices/filters';

// ----------------------------------------------------------------------

function useFilters() {
  const dispatch = useDispatch();
  const { response, APIStatus, error } = useSelector(
    (state: RootState) => state.filters
  );

  return {
    response,
    APIStatus,
    error,
    getFilterValues: () => dispatch(fetchFilterValues())
  };
}

export default useFilters;
