import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  Box,
  IconButton
} from '@material-ui/core';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { CountryFlag } from '../general/CountryFlag';
import { CellTooltip } from './cells/CellTooltip';
import useLocales from '../../hooks/useLocales';
import countries from '../../utils/countries.json';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import { SortBy } from '../../@types/filters';
import { OrderRow } from '../../@types/tableRows';
import { APIStatus } from '../../@types/APIStatus';
import useOrdersTab from '../../hooks/useOrdersTab';
import { textStyles } from '../../theme/TextClasses';
import { useWindowSize } from '../../hooks/useWindowSize';
import useAuth from '../../hooks/useAuth';
import CardTableStatus from '../general/CardTableStatus';
import TableHorizontalScroll from '../general/TableHorizontalScroll';
import SummaryHeaderCell from '../tables/SummaryHeaderCell';

type Props = {
  rowsPerPage: number;
  sortBy: SortBy;
  onSortChange: (sortBy: SortBy) => void;
};

export default function OrdersTable({
  rowsPerPage,
  sortBy,
  onSortChange
}: Props) {
  const history = useHistory();
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const countryList: any = countries;
  const { height } = useWindowSize();
  const heightRow = 76;
  const [numRowsResult, setNumRowsResult] = useState<number>(3);
  const classes = TableStyleClasses({
    height: heightRow,
    numRows: numRowsResult,
    maxSize: height
  });
  const targetOberverRef = useRef<HTMLTableRowElement | null>(null);
  const textClasses = textStyles({ lines: 1 });
  const {
    summary,
    data: {
      orderList: {
        APIStatus: state,
        error,
        response: { rows: data }
      }
    }
  } = useOrdersTab();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  useEffect(() => setNumRowsResult(data.length), [data]);

  const navigateToOrder = (orderId: string, buttonPressed: number = 3) => {
    if (buttonPressed === 0) {
      history.push(`/order/${orderId}`);
    } else if (buttonPressed === 1) {
      window.open(`/order/${orderId}`);
    }
  };

  return (
    <Paper variant="outlined" className={classes.paper}>
      <TableHorizontalScroll
        forwardedRef={targetOberverRef}
        leftStart={220}
        sortBy={sortBy}
        onSortChange={onSortChange}
        summary={summary}
      >
        <TableContainer className={classes.tableContainer}>
          <CardTableStatus
            isLoading={state === APIStatus.PENDING}
            isError={error !== undefined}
            isNoResult={state === APIStatus.FULFILLED && data.length === 0}
          />
          <Table stickyHeader>
            <TableHead>
              <TableRow ref={targetOberverRef}>
                {summary.map((header, i) => (
                  <SummaryHeaderCell
                    key={header.field}
                    index={i}
                    field={header.field}
                    onSortChange={onSortChange}
                    sticky={i === 0}
                    canSort={header.canSort}
                    active={sortBy.by == header.field}
                    header={{
                      align: header.align,
                      label: header.label,
                      subLabel: header.subLabel
                    }}
                    direction={sortBy.order}
                    summary={header.summary}
                    minWidth={header.minWidth}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: OrderRow, key: number) => (
                <TableRow
                  hover
                  key={`${key}-${row.orderId}`}
                  className={classes.tableRow}
                >
                  <TableCell
                    align="left"
                    className={`${classes.cellSticky} withPadding`}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <IconButton
                        className={classes.linkIconButton}
                        component="button"
                        onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          navigateToOrder(row.orderId, e.button);
                        }}
                      >
                        <Icon icon="eva:external-link-fill" />
                      </IconButton>
                      <CellTooltip
                        align="start"
                        tooltip={`${translate('channel')} ${row.channelId}`}
                        title={row.orderExtId}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ padding: '0px 16px', maxWidth: '240px' }}
                  >
                    <Typography
                      textAlign="center"
                      variant="body1"
                      className={textClasses.ellipsis}
                    >
                      {row.channel}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ padding: '0px 16px' }}>
                    <Box sx={{ width: '90px' }}>
                      <Typography
                        variant="body1"
                        className={textClasses.ellipsis}
                      >
                        {row.orderDate}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '0px 16px' }}>
                    <Typography
                      variant="body1"
                      className={textClasses.ellipsis}
                    >
                      {row.orderStatusDE}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '0px 16px' }}>
                    <Typography
                      variant="body1"
                      className={textClasses.ellipsis}
                    >
                      {row.orderPaymentMethod}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '0px 16px' }}>
                    {row.orderCountryIso === 'XX' ? (
                      <Typography
                        variant="body1"
                        sx={{ marginLeft: '6px', paddingRight: '18px' }}
                      >
                        {row.orderCountryIso}
                      </Typography>
                    ) : (
                      <Box
                        sx={{
                          paddingRight: '18px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box sx={{ marginRight: '8px' }}>
                          <CountryFlag
                            widthPx={22}
                            code={row.orderCountryIso}
                          />
                        </Box>
                        <Typography
                          variant="body1"
                          className={textClasses.ellipsis}
                        >
                          {countryList[row.orderCountryIso]}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ textAlign: 'center', paddingRight: '18px' }}
                      style={{
                        textDecoration: row.orderIsCancelled
                          ? 'line-through'
                          : 'none'
                      }}
                    >
                      {row.orderItems}
                    </Typography>
                  </TableCell>

                  <TableCell align="center" sx={{ padding: '0px 16px' }}>
                    <Typography
                      variant="body1"
                      textAlign="center"
                      style={{
                        textDecoration: row.orderIsCancelled
                          ? 'line-through'
                          : 'none'
                      }}
                    >
                      {new Intl.NumberFormat(currentLang.value, {
                        style: 'currency',
                        currency: iso
                      }).format(Number(row.orderAmountNet))}
                    </Typography>
                  </TableCell>

                  <TableCell align="center" sx={{ padding: '0px 16px' }}>
                    <Typography
                      variant="body1"
                      textAlign="center"
                      style={{
                        textDecoration: row.orderIsCancelled
                          ? 'line-through'
                          : 'none'
                      }}
                    >
                      {new Intl.NumberFormat(currentLang.value, {
                        style: 'currency',
                        currency: iso
                      }).format(Number(row.orderAmount))}
                    </Typography>
                  </TableCell>

                  <TableCell align="center" sx={{ padding: '0px 16px' }}>
                    {row.orderTypeId === 1 && row.orderIsCancelled !== true ? (
                      <Typography
                        variant="subtitle2"
                        sx={{ textAlign: 'center', paddingRight: 1 }}
                      >
                        {new Intl.NumberFormat(currentLang.value, {
                          style: 'percent',
                          maximumFractionDigits: 2
                        }).format(row.orderMargin / 100)}
                      </Typography>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableHorizontalScroll>
    </Paper>
  );
}
